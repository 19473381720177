import React from 'react'
import { FaUnlock } from 'react-icons/fa'

const PdfDashboard = () => {
    return (
        <>   <div className='container'>
            <h2 className='text-center mt-5 mb-4'>Our Packages</h2>
            <div className='row'>
                <div className='col-md-4 mb-4'>
                    <div className='card'>
                        <img src='./images/networking.png' className='card-img-top' alt='img' />
                        <div className='card-body'>
                            <h6 className='card-title'>Computer Networking | BCA, MCA, B.Tech | UGC-NET</h6>
                            <ul>
                                <li>One time fee</li>
                                <li>Updated content</li>
                            </ul>
                            <div style={{ justifyContent: 'center', display: 'flex' }}>
                                <a href='https://drive.google.com/file/d/1m-P4DI6Gab2JqgVyeEi62ka3KOBna2qJ/view?usp=sharing' className='pay_now_btn'>
                                    <FaUnlock />
                                    &nbsp;&nbsp;&nbsp;Download now
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div></>
    )
}

export default PdfDashboard