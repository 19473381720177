import React from 'react';

const SectionOne = () => {
  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-md-7'>
            <h3 className='mt-2'>Welcome to codingveda</h3>
            <hr />
            <p style={{ textAlign: 'justify' , color:'black'}}>
              CodingVeda is the great platform from which we can gain knowledge about great topics related technologies.
              <br />
              Here you can build knowledge about colour print, typography and many more and also HTML, CSS which are
              required in modern technologies and also necessary for making websites and learning about web design. Most
              important this is useful for communicating more customers in your business. I hope you will get enough of
              all knowledge u need.
            </p>
          </div>
          <div className='col-md-5'>
            <img src="images/videogif.gif" alt="" className='homevideo'/>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionOne;
