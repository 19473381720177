export default {
    development: {
      site_api: 'http://localhost:3002'
  
    },
    production: {
      site_api: 'http://localhost:3002'
    },
  };



  