import React, { useEffect } from 'react';
import Sidebar from './Common/Sidebar';
import Contentarea from './Common/Contentarea';
import { useSelector } from 'react-redux';

const C = (props) => {
  const selectedContent = useSelector((state) => state.data.selectedContent);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-2 p-0'>
            <Sidebar />
          </div>
          <div className='col-md-8 contentdiv'>
            <Contentarea selectedContent={selectedContent} />
          </div>
          <div className='col-md-2'>
            <h5 style={{marginTop:'6px'}}>ReactJs Setup Tutorial</h5>
          <iframe style={{marginTop:'5px'}} width="100%" height="auto" src="https://www.youtube.com/embed/AtGvDjQu2gU?si=HEJTtWkx-oAYX21j" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </>
  );
};

export default C;
