import React, { useState } from "react";
import "./ExerciseTest.css";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'

const ExerciseTest = () => {
  const navigate = useNavigate();
  const [mobile, setMobile] = useState();
  const [name, setName] = useState();


  const startQuiz = () => {
    if (mobile && name) {
      // alert("succesfull login");
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Thanks for login',
        showCloseButton: false,
        showConfirmButton: false,
        timer: 2000
      })
      // <Redirect to="/Polices"/>
      localStorage.setItem("user", JSON.stringify({name:name, mobile:mobile}))
      navigate("/Polices") 
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Enter name and mobile number',
        showCloseButton: false,
        showConfirmButton: false,
        timer: 5000
      })
    }
  };
  return (
    <>
    <div className="container-fluid Form_background" >
        <div className="row">
            <div className="col-md-6 mt-4 text-center">
            <img className="lottie_files w-100" style={{maxWidth:"70vh"}} src="./images/exams.gif"/>
            </div>
            <div className="col-md-6 text-center">
            <h3 className="login_heading">Enter mobile number to start quiz</h3>
            <img src='./images/quiz_logo.png' className='img_main_login mt-4'/>
            <form action="">
              <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter name"/>
              <br/>
              <input type="number" value={mobile} onChange={(e) => setMobile(e.target.value)} placeholder="Enter mobile number"/>
              <br />

             
            </form>
            <button onClick={startQuiz} className="login_btns"> Start Quiz </button>
            </div>
        </div>
      </div>
    </>
  );
};

export default ExerciseTest;
