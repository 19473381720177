import React, { useEffect, useState } from 'react';

const Aboutus = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className='aboutus-container'>
      <div className='tabs'>
        <button className={`tab ${activeTab === 0 ? 'active' : ''}`} onClick={() => handleTabClick(0)}>
          About us
        </button>
      </div>
      <div className='tab-content'>
        {activeTab === 0 && (
          <div>
            <h4>
              <b>About Us</b>
            </h4>
            <h5>Codingveda.com</h5>
            <p>
              www.codingveda.com is open source study platform to provide free of cost coding knowledge to student, the
              best online study source platform to gain knowledge.
            </p>

            <p>mail: codingveda.com@gmail.com</p>
            <hr />
            <h5>Easy Learning</h5>
            <p>
              codingveda.com focus on simplecity.
              <br />
              codingveda.com design and content easy and way to straight-forward learning.
              <br />
              codingveda.com having simple user interface to understand .<br />
              mail: codingveda.com@gmail.com
            </p>
            <hr />
            <h5>Exercise Test for best practice</h5>
            <p>
              www.codingveda.com provide free exercise test to improve your knowledge.
              <br />
              mail: codingveda.com@gmail.com
            </p>
            <br />
            <h5>Comment If any Errors</h5>
            <p>
              If you find an error, or broken link, please tell us about it.
              <br />
              If you find any wrong question and answer please comment about that
              <br />
              Use the button "chat" at the bottom-right of each page
              <br />
              mail: codingveda.com@gmail.com
            </p>
            <hr />
            <h5>Add Our Link</h5>
            <p>
              If you want others descover codingveda.com, please Add our link.
              <br />
              add link on webpage or social media platform like facebook, instagram, youtube.
            </p>
          </div>
        )}
      
      </div>
    </div>
  );
};

export default Aboutus;
