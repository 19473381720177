import React, { useState } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { Link } from 'react-router-dom';

const Polices = () => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8 mt-2">
            <h4>Instructions To The Candidates Before The Examination Starts</h4>
            <IoIosArrowForward /> Arrange for stable Internet connectivity.<br />
            <IoIosArrowForward /> Giving examination on Laptop or Desktop is highly recommended.<br />
            <IoIosArrowForward /> Make sure mobile/laptop is fully charged. Power bank for mobile or UPS/Inverter for laptop/desktop should be arranged for uninterrupted power supply.<br />
            <IoIosArrowForward /> Students should have sufficient data in Fair Usage Policy (FUP) / Internet plan with sufficient data pack of internet service provider.<br />
            <IoIosArrowForward /> Login to the portal 10 min before the online examination start time.<br />
            <IoIosArrowForward /> Close all browsers/tabs before starting the online examination.<br />
            <IoIosArrowForward /> Once the exam starts, do not switch to any other window/tab. On doing so, your attempt may be considered as malpractice and your exam may get terminated.<br />
            <IoIosArrowForward /> Do Not Pickup/Receive the Call during the exam if you are giving the exam on mobile. This also will be treated as changing the window.<br />
            <IoIosArrowForward /> To avoid unwanted pop-ups, use of Ad Blocker is recommended.<br />
            <IoIosArrowForward /> Clear browser cache memory on mobile and laptops. Clear browsing history and also delete temp files.<br /><br />
          </div>
          <div className="col-md-2"></div>
        </div>

        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <input type="checkbox" onChange={handleCheckboxChange} /> Agree
          </div>
          <div className="col-md-2"></div>
        </div>

        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8 mt-3">
            <button className='previous_btns'>
              <Link to={"/"} className="prebtn">Previous</Link>
            </button>
            <button className='next_btns' disabled={!isChecked}>
              <Link to={isChecked ? "/quiz-list" : alert("Please accept our polices")} className="nextbtn">Next</Link>
            </button>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </>
  );
};

export default Polices;
