import React, { useEffect, useState } from 'react';
import SectionOne from './SectionOne';
import SectionFour from './SectionFour';
import SectionThree from './SectionThree';
import SectionTwo from './SectionTwo';
import axios from 'axios';
const apiKey = process.env.REACT_APP_SECURE_KEY;

const Home = () => {
  const [data, setData] = useState();

  useEffect(() => {
    axios
      .get(`${apiKey}/course/get_topic`)
      .then(function (response) {
        setData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  return (
    <>
      <SectionOne />
      <br />
      <SectionTwo data={data} />
      <SectionThree />
      <SectionFour />
    </>
  );
};

export default Home;
