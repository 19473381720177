import Config from '../config';

export const config = {
    get_c_language : `${Config.site_api}/dashboard/get_c_language`,

    get_cpp_language : `${Config.site_api}/dashboard/get_cpp_language`,

    get_java_language : `${Config.site_api}/dashboard/get_java_language`,

    get_computer_language : `${Config.site_api}/dashboard/get_computer_language`,

    get_javascript_language : `${Config.site_api}/dashboard/get_javascript_language`,

    get_html_language : `${Config.site_api}/dashboard/get_html_language`,

    get_css_language : `${Config.site_api}/dashboard/get_css_language`,

    get_php_language : `${Config.site_api}/dashboard/get_php_language`,

    get_react_language : `${Config.site_api}/dashboard/get_react_language`,

    get_language : `${Config.site_api}/dashboard/get_language`,
}
