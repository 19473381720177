import React from "react";
import { Link } from "react-router-dom";

const SectionTwo = ({ data = {} }) => {
  const dataEntries = Object.entries(data);
  return (
    <>
      <div className="container-fluid course_bg">
        <div className="row text-center text-white">
          <h4 className="mt-1 p-3">Our Courses</h4>
        </div>
        <div className="row text-center justify-content-center">
          {dataEntries.map(([key, item], index) => (
            <div className="col-md-5" style={{ marginBottom: "24px" }}>
              <div
                className="card html_bg"
                style={{ backgroundColor: item.bgcolor }}
              >
                <div className="card-title">
                  <h2>
                    <b style={{ textTransform: "uppercase" }}>{item?.name}</b>
                  </h2>
                </div>
                <div className="card-body">
                  <h6>{item?.title}</h6>
                  <Link
                    to={`${item?.name}/${item?._id}`}
                    type="button"
                    className="card_button mt-4 p-1"
                  >
                    Learn {item?.name}
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
        <br />
      </div>
    </>
  );
};

export default SectionTwo;
