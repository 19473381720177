import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Header from './Components/Header';
import './Style.css';
import Home from './Components/Home/Home';
import Footer from './Components/Footer';
import C from './Course/C';
import Admin from './Components/AdminPannel/Admin';
import LoginAdmin from './Components/Login/LoginAdmin';
import Howto from './Components/Howto';
import TryEditor from './Components/TryEditor';
import ExerciseTest from './Components/ExerciseTest/ExerciseTest';
import Aboutus from './Components/Aboutus';
import Blogs from './Components/Blogs/Blogs';
import ViewBlogs from './Components/Blogs/ViewBlogs';
import Signin from './Components/Sign/Signin';
import Course from './Components/Course/Course';
import Dashboard from './Components/Course/Dashboard';
import Polices from './Components/ExerciseTest/Polices';
import QuizList from './Components/ExerciseTest/QuizList';
import StartQuiz from './Components/ExerciseTest/StartQuiz';
import Result from './Components/ExerciseTest/Result';
import TermAndCondition from './Components/TermAndCondition';
import PrivacyAndPolicy from './Components/PrivacyAndPolicy';
import Paynow from './Components/Paynow';
import PdfDashboard from './Components/PdfDashboard';

const App = () => {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />

          <Route path='/C/:id' element={<C />} />
          <Route path='/CPlus/:id' element={<C />} />
          <Route path='/java/:id' element={<C />} />
          <Route path='/computer/:id' element={<C />} />
          <Route path='/javascript/:id' element={<C />} />
          <Route path='/html/:id' element={<C />} />
          <Route path='/css/:id' element={<C />} />
          <Route path='/reactjs/:id' element={<C />} />
          <Route path='/php/:id' element={<C />} />
          <Route path='/bootstrap/:id' element={<C />} />
          <Route path='/msoffice/:id' element={<C />} />
          <Route path='/python/:id' element={<C />} />
          <Route path='/dbms/:id' element={<C />} />
          <Route path='/dsa/:id' element={<C />} />
          <Route path='/ai/:id' element={<C />} />
          <Route path='/spoken English/:id' element={<C />} />
          <Route path='/Computer Networking/:id' element={<C />} />

          <Route path='/blogs' element={<Blogs />} />
          <Route path='/Pdfdashboard' element={<PdfDashboard/>}/>
          <Route path='/paynow' element={<Paynow/>}/>
          <Route path='/course' element={<Course />} />
          <Route path='/viewblog/:id' element={<ViewBlogs />} />
          <Route path='/Howto' element={<Howto />} />
          <Route path='/about-us' element={<Aboutus />} />
          <Route path='/login' element={<LoginAdmin />} />
          <Route path='/TryEditor' element={<TryEditor />} />
          <Route path='/admin/admin' element={<Admin />} />
          <Route path='/signin' element={<Signin />} />
          <Route path='/Dashboard' element={<Dashboard />} />
          <Route path='/ExerciseTest' element={<ExerciseTest />} />
          <Route path='Polices' element={<Polices/>}/>
          <Route path='/start-quiz/:type' element={<StartQuiz/>}/>
          <Route path='/quiz-list' element={<QuizList/>}/>
          <Route path='Result' element={<Result/>}/>
          <Route path='terms-and-condition' element={<TermAndCondition/>}/>
          <Route path="privacy-policy" element={<PrivacyAndPolicy/>}/>
        </Routes>
        <Footer />
      </Router>
    </>
  );
};

export default App;
