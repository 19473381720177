// slice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedContent: [],
  subTopics: [], // Add subtopics array state
};

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setSelectedContent: (state, action) => {
      state.selectedContent = action.payload;
    },
    setSubtopics: (state, action) => {
      state.subTopics = action.payload;
    },
  },
});

export const { setSelectedContent, setSubtopics } = dataSlice.actions;
export default dataSlice.reducer;
