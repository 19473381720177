import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { FaLinkedin, FaFacebookSquare, FaTwitterSquare, FaWhatsapp } from 'react-icons/fa';

const apiKey = process.env.REACT_APP_SECURE_KEY;

const ViewBlogs = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`${apiKey}/course/get_blog_details/${id}`)
      .then(function (response) {
        setData(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const currentUrl = window.location.href;

  const shareUrls = {
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`,
    linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(currentUrl)}`,
    twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}`,
    whatsapp: `https://api.whatsapp.com/send?text=${encodeURIComponent(currentUrl)}`,
  };

  return (
    <>
      <div className='container'>
        <div className='row pt-3'>
          <div className='col-md-8'>
            <div className='row'>
              {loading ? (
                <div className='text-center'>Loading...</div>
              ) : (
                <div className='row'>
                  {data.map((item, index) => (
                    <div key={index} className='mb-4'>
                      <h4>{item.blog_heading}</h4>
                      <div dangerouslySetInnerHTML={{ __html: item.blog_content }} />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className='col-md-4'>
            <div className='card' style={{ backgroundColor: '#E3F2C1' }}>
              <div className='card-body'>
                <div className='d-flex' style={{ gap: '30px' }}>
                  <img src='/images/lokesh.jpg' alt='' style={{ width: '70px' }} />
                  <a
                    className='btn-floating btn-sm rgba-white-slight mx-1'
                    href='https://www.linkedin.com/in/lokesh-rewar-377b73141/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <FaLinkedin className='mt-5' style={{ fontSize: '27px', color: '#005C54' }} />
                  </a>
                </div>
                <div>
                  <h5>Lokesh Rewar</h5>
                  <p>Founder of Codingveda - the best data collection of most useful languages.</p>
                  <hr />
                  <p>
                    Hi, I have written and developed this site so that students may learn computer science related
                    technologies easily. I'm committed to providing easy and in-depth tutorials on various technologies.
                    No one is perfect in this world and nothing is eternally best. But we can try to be better. I hope
                    it will help you a lot.
                  </p>
                </div>
              </div>
            </div>
            <br />
            <div className='card' style={{ backgroundColor: '#E3F2C1' }}>
              <div className='card-body'>
                <h5>Share with your community!</h5>
                <div className='d-flex' style={{ gap: '20px' }}>
                  <a
                    className='btn-floating btn-sm rgba-white-slight mx-1'
                    href={shareUrls.facebook}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <FaFacebookSquare style={{ fontSize: '27px', color: '#005C54' }} />
                  </a>
                  <a
                    className='btn-floating btn-sm rgba-white-slight mx-1'
                    href={shareUrls.linkedin}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <FaLinkedin style={{ fontSize: '27px', color: '#005C54' }} />
                  </a>
                  <a
                    className='btn-floating btn-sm rgba-white-slight mx-1'
                    href={shareUrls.twitter}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <FaTwitterSquare style={{ fontSize: '27px', color: '#005C54' }} />
                  </a>
                  <a
                    className='btn-floating btn-sm rgba-white-slight mx-1'
                    href={shareUrls.whatsapp}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <FaWhatsapp style={{ fontSize: '27px', color: '#005C54' }} />
                  </a>
                </div>
              </div>
            </div>
            <br />
            <div>
              <h5>In this article</h5>
            </div>
            <div>
              <ul>
                <li>Exploring Generative AI in Content Creation</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewBlogs;
