// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyDrNy64n8rsGubhy_M6dhdmbZtSC91aWY4',
  authDomain: 'codingveda-user-login.firebaseapp.com',
  projectId: 'codingveda-user-login',
  storageBucket: 'codingveda-user-login.appspot.com',
  messagingSenderId: '18214809654',
  appId: '1:18214809654:web:a92d84f96fa6b0f5cce897',
  measurementId: 'G-900M3ZHB5S',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, provider };
