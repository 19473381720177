import React, { useEffect, useState } from "react";
import { Controlled as CodeMirror } from "react-codemirror2";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/mode/xml/xml";

const CodeEditor = () => {
  const [code, setCode] = useState("");
  const [iframeSrcDoc, setIframeSrcDoc] = useState("");

  const handleChange = (editor, data, value) => {
    setCode(value);
  };

  const runCode = () => {
    setIframeSrcDoc(code);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <div>
              <button onClick={runCode} className="run_code_btn">
                Run Code
              </button>
            </div>
            <CodeMirror
              className="codemirror"
              value={code}
              onBeforeChange={(editor, data, value) => {
                setCode(value);
              }}
              onChange={handleChange}
              options={{
                mode: "xml",
                theme: "material",
                lineNumbers: true,
              }}
              style={{ width: "50%" }}
            />
          </div>
          <div className="col-md-6">
            <div>
              <button className="run_code_btn">
                Output
              </button>
            </div>
            <iframe
              title="Code Result"
              srcDoc={iframeSrcDoc}
              style={{
                width: "100%",
                minHeight: "300px",
                border: "1px solid #ccc",
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CodeEditor;
