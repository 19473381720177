import React from 'react';
import { Link } from 'react-router-dom';
import { FaWhatsapp } from "react-icons/fa6";

const Footer = () => {
  return (
    <>
      <footer className='page-footer font-small mdb-color'>
        <div className='container-fluid text-center text-md-left footer'>
          <div className='row text-center text-md-left mt-3 pb-3'>
            <div className='col-md-3 col-lg-3 col-xl-3 mx-auto mt-3'>
              <Link className='navbar-brand' to={'/'}>
                <img src='/images/codingveda.png' width='250' alt='LOGO' />
              </Link>
              <p style={{ textAlign: 'justify' }}>
                Hi, I have written and developed this site so that students may learn computer science related
                technologies easily. I'm committed to provide easy and in-depth tutorials on various technologies. No
                one is perfect in this world and nothing is eternally best. But we can try to be better. I hope it will
                help you a lot. - Lokesh Rewar
              </p>
            </div>
            <hr className='w-100 clearfix d-md-none'></hr>
            <div className='col-md-2 col-lg-2 col-xl-2 mx-auto mt-3'>
              <h6 className='text-uppercase mb-4 font-weight-bold'>Courses</h6>
              <p>
                <Link to='/C/6522f7404e8e5aa51d50935f' className='remove_underline'>C</Link>
              </p>
              <p>
                <Link to='/CPlus/6522f8274e8e5aa51d50936a' className='remove_underline'>C++</Link>
              </p>
              <p>
                <Link to='/html/6532c3fb4c9e8091963ed079' className='remove_underline'>HTML</Link>
              </p>
              <p>
                <Link to='/javascript/6532c3f64c9e8091963ed078' className='remove_underline'>Javascript</Link>
              </p>
              <p>
                <Link to='/python/665dde769a80c3145bf9d1f5' className='remove_underline'>PYTHON</Link>
              </p>
              <p>
                <Link to='/computer/6532c3f14c9e8091963ed077' className='remove_underline'>Computer</Link>
              </p>
              <p>
                <Link to='/howto' className='remove_underline'>How To</Link>
              </p>
              <p>
                <Link to='/ExerciseTest' className='remove_underline'>ExerciseTest</Link>
              </p>
            </div>
            <hr className='w-100 clearfix d-md-none'></hr>
            <div className='col-md-3 col-lg-2 col-xl-2 mx-auto mt-3'>
              <h6 className='text-uppercase mb-4 font-weight-bold'>About Us</h6>
              <p>
                <Link to='/blogs' className='remove_underline'>Blogs</Link>
              </p>
              <p>
                <Link to='/Course' className='remove_underline'>Courses</Link>
              </p>
              <p>
                <Link to='/about-us' className='remove_underline'>About us</Link>
              </p>
              <p>
                <Link to='/privacy-policy' className='remove_underline'>Privacy & Policy</Link>
              </p>
              <p>
                <Link to='/terms-and-condition' className='remove_underline'>Terms & conditions</Link>
              </p>
            </div>
            <hr className='w-100 clearfix d-md-none'></hr>
            <div className='col-md-4 col-lg-3 col-xl-3 mx-auto mt-3'>
              <h6 className='text-uppercase mb-4 font-weight-bold'>Contact</h6>
              <p>
                <i className='fa fa-home' style={{ fontSize: '22px' }}></i> Sikar, Rajasthan, India
              </p>
              <p>
                <i className='fa fa-envelope'></i> codingveda.com@gmail.com
              </p>
              <p>
                <i className='fa fa-phone'></i> + 91 952 113 3660
              </p>
            </div>
          </div>
          <hr></hr>
          <div className='row d-flex align-items-center footerdown'>
            <div className='col-md-7 col-lg-8'>
              <p className='text-center text-md-left'>© Copyright CodingVeda 2024. All rights reserved.</p>
            </div>
            <div className='col-md-5 col-lg-4 ml-lg-0 mt-3'>
              <div className='text-center text-md-right'>
                <ul className='list-unstyled list-inline'>
                  <li className='list-inline-item'>
                    <a
                      className='btn-floating btn-sm rgba-white-slight mx-1'
                      href='https://www.facebook.com/lokesh.rewar'
                      target='_blank'
                    >
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li className='list-inline-item'>
                    <a
                      className='btn-floating btn-sm rgba-white-slight mx-1'
                      href='https://twitter.com/LokeshRewar'
                      target='_blank'
                    >
                      <i className='fa fa-twitter'></i>
                    </a>
                  </li>
                  <li className='list-inline-item'>
                    <a
                      className='btn-floating btn-sm rgba-white-slight mx-1'
                      href='https://www.instagram.com/lokesh_rewar/'
                      target='_blank'
                    >
                      <i className='fa fa-instagram'></i>
                    </a>
                  </li>
                  <li className='list-inline-item'>
                    <a className='btn-floating btn-sm rgba-white-slight mx-1' href='www.codingveda.com'>
                      <i className='fa fa-google-plus'></i>
                    </a>
                  </li>
                  <li className='list-inline-item'>
                    <a
                      className='btn-floating btn-sm rgba-white-slight mx-1'
                      href='https://www.linkedin.com/in/lokesh-rewar-377b73141/'
                      target='_blank'
                    >
                      <i className='fa fa-linkedin'></i>
                    </a>
                  </li>
                  <li className='list-inline-item'>
                    <a
                      className='btn-floating btn-sm rgba-white-slight mx-1'
                      href='https://www.youtube.com/futureexam1'
                      target='_blank'
                    >
                      <i className='fa fa-youtube-play'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <a href='https://wa.me/919521133660' className='chat-icon'>
          <FaWhatsapp className='chat_icon_image' />
        </a>
      </footer>
    </>
  );
};

export default Footer;
