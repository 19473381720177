import React from 'react';
import { auth, provider } from './firebase';
import { FcGoogle } from 'react-icons/fc';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router';

const Signin = () => {
  const navigate = useNavigate();
  const handleGoogleSignIn = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        navigate('/');
        console.log('User:', user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.error('Error:', errorCode, errorMessage, email, credential);
      });
  };

  return (
    <>
      <div className='container p-2'>
        <div className='row'>
          <div className='col-md-7 signinleft'>
            <img src='/images/exams.gif' style={{ maxWidth: '310px' }} alt='LOGO' />
          </div>
          <div className='col-md-5 signinright'>
            <div className='row d-flex justify-content-center'>
              <img src='/images/codingveda.png' alt='LOGO' style={{ width: '100px' }} />
            </div>
            <div className='row text-center'>
              <h4 style={{ color: 'white' }}>Login to your Account</h4>
            </div>
            <div className='row justify-content-center'>
              <button className='login_btn' onClick={handleGoogleSignIn}>
                <FcGoogle style={{ fontSize: '25px' }} /> Sign in with Google
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signin;
