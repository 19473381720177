import React, { useEffect } from 'react';
import { FaLock } from 'react-icons/fa';

const Paynow = () => {
    const handlePayment = () => {
        const options = {
            key: 'rzp_live_7vkw17RunFxN2d',
            amount: 900, 
            currency: 'INR',
            name: 'codingveda',
            description: 'Course Fee',
            image: './images/networking.png',
            handler: function (response) {
                console.log(response);
                if (response.razorpay_payment_id) {
                    // Store payment status in local storage
                    localStorage.setItem('paymentStatus', 'paid');
                    window.location.href = '/Pdfdashboard';
                }
            },
            prefill: {
                name: 'lokesh kumar rewar',
                email: 'lokeshrewar@gmail.com',
                contact: '95221133660',
            },
            theme: {
                color: '#3399cc',
            },
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.open();
    };

    useEffect(() => {
        // Redirect if the user has already paid
        const paymentStatus = localStorage.getItem('paymentStatus');
        if (paymentStatus === 'paid') {
            window.location.href = '/Pdfdashboard';
        } else {
            window.scrollTo(0, 0);
        }
    }, []);

    return (
        <div className='container'>
            <h2 className='text-center mt-5 mb-4'>Our Packages</h2>
            <div className='row'>
                <div className='col-md-4 mb-4'>
                    <div className='card'>
                        <img src='./images/networking.png' className='card-img-top' alt='img' />
                        <div className='card-body'>
                            <h6 className='card-title'>Computer Networking | BCA, MCA, B.Tech | UGC-NET</h6>
                            <ul>
                                <li>One time fee</li>
                                <li>Updated content</li>
                            </ul>
                            <h6>
                                <b>₹ 9 /-</b>
                                <br />
                                Price <strike>₹ 199 /-</strike>
                            </h6>
                            <div style={{ justifyContent: 'center', display: 'flex' }}>
                                <button onClick={handlePayment} className='pay_now_btn'>
                                    <FaLock />
                                    &nbsp;&nbsp;&nbsp;Pay Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Paynow;



// import React, { useEffect } from 'react'
// import { FaLock } from 'react-icons/fa'

// const Paynow = () => {
//     const handlePayment = () => {
//         const options = {
//           key: 'rzp_live_7vkw17RunFxN2d',
//           amount: 900, 
//           currency: 'INR',
//           name: 'codingveda',
//           description: 'Course Fee',
//           image: './images/networking.png',
//           handler: function (response) {
//             console.log(response);
//             if (response.razorpay_payment_id) {
//               window.location.href = '/Pdfdashboard';
//             }
//           },
//           prefill: {
//             name: 'lokesh kumar rewar',
//             email: 'lokeshrewar@gmail.com',
//             contact: '95221133660',
//           },
//           theme: {
//             color: '#3399cc',
//           },
//         };
    
//         const rzp1 = new window.Razorpay(options);
//         rzp1.open();
//       };
    
//       useEffect(() => {
//         window.scrollTo(0, 0);
//       }, []);
    
//   return (
//     <>
//         <div className='container'>
//         <h2 className='text-center mt-5 mb-4'>Our Packages</h2>
//         <div className='row'>
//           <div className='col-md-4 mb-4'>
//             <div className='card'>
//               <img src='./images/networking.png' className='card-img-top' alt='img' />
//               <div className='card-body'>
//                 <h6 className='card-title'>Computer Networking | BCA, MCA, B.Tech | UGC-NET</h6>
//                 <ul>
//                   <li>One time fee</li>
//                   <li>Updated content</li>
//                 </ul>
//                 <h6>
//                   <b>₹ 9 /-</b>
//                   <br />
//                   Price <strike>₹ 199 /-</strike>
//                 </h6>
//                 <div style={{ justifyContent: 'center', display: 'flex' }}>
//                   <button onClick={handlePayment} className='pay_now_btn'>
//                     <FaLock />
//                     &nbsp;&nbsp;&nbsp;Pay Now
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   )
// }

// export default Paynow