import React from 'react';
import '../AdminPannel/Admin.css';
import { BsCalendarCheck } from 'react-icons/bs';
import { RiMoneyDollarCircleFill } from 'react-icons/ri';
import { BiTask } from 'react-icons/bi';
import { BiComment } from 'react-icons/bi';
import { FiLogOut } from 'react-icons/fi';
import { BsPencilSquare } from 'react-icons/bs';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const Admin = () => {
  return (
    <>
      <div id='wrapper'>
        <ul className='navbar-nav bg-gradient-primary sidebar sidebar-dark accordion' id='accordionSidebar'>
          <a className='sidebar-brand d-flex align-items-center justify-content-center' href='index.html'>
            <div className='sidebar-brand-icon rotate-n-15'>
              <i className='fas fa-laugh-wink'></i>
            </div>
            <div className='sidebar-brand-text mx-3'>CodingVeda</div>
          </a>

          <hr className='sidebar-divider my-0' />

          <li className='nav-item active'>
            <a className='nav-link' href='/admin/admin'>
              <i className='fas fa-fw fa-tachometer-alt'></i>
              <span>Dashboard</span>
            </a>
          </li>

          <hr className='sidebar-divider' />
        </ul>

        <div id='content-wrapper' className='d-flex flex-column'>
          <div id='content'>
            <div className='container-fluid'>
              <div className='d-sm-flex align-items-center justify-content-between mb-4'>
                <h1 className='h3 mb-0 text-gray-800'>Dashboard</h1>
                <a href='#' className='d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm'>
                  <FiLogOut />
                </a>
              </div>

              <div className='row'>
                <div className='col-xl-3 col-md-6 mb-4'>
                  <div className='card border-left-primary shadow h-100 py-2'>
                    <div className='card-body'>
                      <div className='row no-gutters align-items-center'>
                        <div className='col mr-2'>
                          <div className='text-xs font-weight-bold text-primary text-uppercase mb-1'>
                            Earnings (Monthly)
                          </div>
                          <div className='h5 mb-0 font-weight-bold text-gray-800'>$40,000</div>
                        </div>
                        <div className='col-auto'>
                          <BsCalendarCheck />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-xl-3 col-md-6 mb-4'>
                  <div className='card border-left-success shadow h-100 py-2'>
                    <div className='card-body'>
                      <div className='row no-gutters align-items-center'>
                        <div className='col mr-2'>
                          <div className='text-xs font-weight-bold text-success text-uppercase mb-1'>Earnings (Annual)</div>
                          <div className='h5 mb-0 font-weight-bold text-gray-800'>$215,000</div>
                        </div>
                        <div className='col-auto'>
                          <RiMoneyDollarCircleFill />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-xl-3 col-md-6 mb-4'>
                  <div className='card border-left-info shadow h-100 py-2'>
                    <div className='card-body'>
                      <div className='row no-gutters align-items-center'>
                        <div className='col mr-2'>
                          <div className='text-xs font-weight-bold text-info text-uppercase mb-1'>Tasks</div>
                          <div className='row no-gutters align-items-center'>
                            <div className='col-auto'>
                              <div className='h5 mb-0 mr-3 font-weight-bold text-gray-800'>50%</div>
                            </div>
                            <div className='col'>
                              <div className='progress progress-sm mr-2'>
                                <div
                                  className='progress-bar bg-info'
                                  role='progressbar'
                                  aria-valuenow='50'
                                  aria-valuemin='0'
                                  aria-valuemax='100'
                                  style={{ width: '50%' }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-auto'>
                          <BiTask />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-xl-3 col-md-6 mb-4'>
                  <div className='card border-left-warning shadow h-100 py-2'>
                    <div className='card-body'>
                      <div className='row no-gutters align-items-center'>
                        <div className='col mr-2'>
                          <div className='text-xs font-weight-bold text-warning text-uppercase mb-1'>Pending Requests</div>
                          <div className='h5 mb-0 font-weight-bold text-gray-800'>18</div>
                        </div>
                        <div className='col-auto'>
                          <BiComment />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-xl-8 col-lg-7'>
                  <div className='card shadow mb-4'>
                    <div className='card-header py-3 d-flex flex-row align-items-center justify-content-between'>
                      <h6 className='m-0 font-weight-bold text-primary'>Language Name</h6>
                      <div className='dropdown no-arrow'>
                        <BsPencilSquare />
                      </div>
                    </div>
                    <div className='card-body'>
                      <Editor
                        toolbarClassName='toolbarClassName'
                        wrapperClassName='wrapperClassName'
                        editorClassName='editorClassName'
                      />
                    </div>
                  </div>
                </div>

                <div className='col-xl-4 col-lg-5'>
                  <div className='card shadow mb-4'>
                    <div className='card-header py-3 d-flex flex-row align-items-center justify-content-between'>
                      <h6 className='m-0 font-weight-bold text-primary'>Updated By</h6>
                      <div className='dropdown no-arrow'>lokesh</div>
                    </div>
                    <div className='card-body'>lokesh</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Admin;
