import { Editor } from '@monaco-editor/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
const apiKey = process.env.REACT_APP_SECURE_KEY;
const Howto = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${apiKey}/course/get_howto`)
      .then(function (response) {
        setData(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='container'>
      <h2 className='text-center mt-5 mb-4'>How To Videos</h2>
      {loading ? (
        <div className='text-center'>Loading...</div>
      ) : (
        <div className='row'>
          {data.map((item, index) => (
            <div className='col-md-6 mb-4' key={index}>
              <div className='card'>
                <div className='card-body'>
                  <h5 className='card-title'>
                    <spans
                      style={{
                        background: '#005c54',
                        color: 'white',
                        borderRadius: '50%',
                        padding: '0px 6px',
                      }}
                    >
                      {index + 1}.
                    </spans>
                    {item.Heading}
                  </h5>
                  <div className='embed-responsive embed-responsive-16by9'>
                    <iframe
                      className='embed-responsive-item'
                      src={item.Video}
                      allowFullScreen
                      style={{ width: '100%' }}
                    />
                  </div>
                  <div className='code_plate'>
                    <Editor
                      theme='vs-dark'
                      height='60vh'
                      defaultLanguage='javascript'
                      value={item.VideoCode}
                      defaultValue={item.VideoCode}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Howto;
